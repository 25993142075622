import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { baseApi } from './base-api';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { productListSlice } from '../../components/pages/ProductList/state/product-list.slice';
import { cartSlice } from '../../components/pages/Cart/state/cart.slice';
import { authSlice } from '../../components/pages/Register/state/auth-slice';

export const persistConfig = {
  key: 'userAuth',
  storage,
};

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer, //it is reducer for base api
  productsList: productListSlice.reducer,
  cart: cartSlice.reducer,
  auth: authSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(baseApi.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
