import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface State {
  cartCount: number;
  cartGrandTotal: number;
  cartGrandWeight: number;
}

const initialState: State = {
  cartCount: 0,
  cartGrandTotal: 0,
  cartGrandWeight: 0,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState: initialState,
  reducers: {
    setCartCount(state, { payload }: PayloadAction<number>) {
      state.cartCount = payload;
    },
    setCartGrandTotal(state, { payload }: PayloadAction<number>) {
      state.cartGrandTotal = payload;
    },
    setCartGrandWeight(state, { payload }: PayloadAction<number>) {
      state.cartGrandWeight = payload;
    },
  },
});

export const { setCartCount, setCartGrandTotal, setCartGrandWeight } =
  cartSlice.actions;
