import { CartTag } from './tags';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api } from '../../environment/environment';
import { RootState } from './store';

export const baseApi = createApi({
  reducerPath: 'baseApi',
  refetchOnMountOrArgChange: 0, // global configuration for the api to disable cache
  tagTypes: [CartTag],
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.baseUrl}`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Accept', 'application/json');

      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
