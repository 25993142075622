import { ApiSettings, ApiVersion } from './environment.model';

export const api: ApiSettings = {
  baseUrl: import.meta.env.VITE_APP_API_URL as string,
  imageUrl: import.meta.env.VITE_APP_IMAGES_URL as string,
};

export const version: ApiVersion = {
  v1: import.meta.env.VITE_APP_API_V1 as string,
};
