export const homeRoute = '/';
export const cartRoute = '/cart';
export const productsRoute = '/products';
export const productDetailsRoute = '/product';
export const productDetailsWithSlugRoute = `${productDetailsRoute}/:slug`;
export const shippingRoute = '/shipping';

export const loginRoute = '/login';
export const registerRoute = '/register';
export const codeRoute = '/code';

export const wishListRoute = '/wishlist';
