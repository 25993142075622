import { Outlet } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import PageLoader from '../../atoms/PageLoader/PageLoader';
import { Grid } from '@mui/material';
const Announcement = lazy(
  () => import('../../molecules/Announcement/Announcement'),
);
const Navbar = lazy(() => import('../../molecules/Navbar/Navbar'));
const Newsletter = lazy(() => import('../../molecules/Newsletter/Newsletter'));
const Footer = lazy(() => import('../../molecules/Footer/Footer'));

const Main = () => {
  return (
    <Grid>
      <Suspense fallback={<PageLoader />}>
        <Navbar />
        <Announcement />
        <Outlet />
        <Newsletter />
        <Footer />
      </Suspense>
    </Grid>
  );
};

export default Main;
