import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../models/user';

export interface State {
  token: string;
  user: User;
}

const initialState: State = {
  token: '',
  user: {
    id: '',
    name: '',
    email: '',
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setToken(state, { payload }: PayloadAction<string>) {
      state.token = payload;
    },
    clearToken(state) {
      state.token = '';
    },
    setUser(state, { payload }: PayloadAction<User>) {
      state.user = payload;
    },
    clearUser(state) {
      state.user = initialState.user;
    },
  },
});

export const { setToken, clearToken, setUser, clearUser } = authSlice.actions;
