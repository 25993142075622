import { Route, Routes } from 'react-router-dom';
import Main from '../components/layouts/Main/Main';
import Auth from '../components/layouts/Auth/Auth';
import PageNotFound from '../components/atoms/PageNotFound/PageNotFound';
import PageLoader from '../components/atoms/PageLoader/PageLoader';
import {
  cartRoute,
  homeRoute,
  loginRoute,
  productsRoute,
  registerRoute,
  productDetailsWithSlugRoute,
  shippingRoute,
  codeRoute,
  wishListRoute,
} from './constants/routes';
import { Suspense, lazy } from 'react';
const Login = lazy(() => import('../components/pages/Login/Login'));
const Register = lazy(() => import('../components/pages/Register/Register'));
const Code = lazy(() => import('../components/pages/Code/Code'));
const Home = lazy(() => import('../components/pages/Home/Home'));
const Cart = lazy(() => import('../components/pages/Cart/Cart'));
const Shipping = lazy(() => import('../components/pages/Shipping/Shipping'));
const Wishlist = lazy(() => import('../components/pages/Wishlist/Wishlist'));
const ProductList = lazy(
  () => import('../components/pages/ProductList/ProductList'),
);
const ProductDetails = lazy(
  () => import('../components/pages/ProductDetails/ProductDetails'),
);

const mainLayoutRoutes = [
  {
    path: homeRoute,
    element: (
      <Suspense fallback={<PageLoader />}>
        <Home />
      </Suspense>
    ),
  },
  {
    path: cartRoute,
    element: (
      <Suspense fallback={<PageLoader />}>
        <Cart />
      </Suspense>
    ),
  },
  {
    path: productsRoute,
    element: (
      <Suspense fallback={<PageLoader />}>
        <ProductList />
      </Suspense>
    ),
  },
  {
    path: productDetailsWithSlugRoute,
    element: (
      <Suspense fallback={<PageLoader />}>
        <ProductDetails />
      </Suspense>
    ),
  },
  {
    path: shippingRoute,
    element: (
      <Suspense fallback={<PageLoader />}>
        <Shipping />
      </Suspense>
    ),
  },
  {
    path: wishListRoute,
    element: (
      <Suspense fallback={<PageLoader />}>
        <Wishlist />
      </Suspense>
    ),
  },
];

const authLayoutRoutes = [
  {
    path: loginRoute,
    element: (
      <Suspense fallback={<PageLoader />}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: registerRoute,
    element: (
      <Suspense fallback={<PageLoader />}>
        <Register />
      </Suspense>
    ),
  },
  {
    path: codeRoute,
    element: (
      <Suspense fallback={<PageLoader />}>
        <Code />
      </Suspense>
    ),
  },
];

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />}>
        {mainLayoutRoutes.map((route) => (
          <Route path={route.path} element={route.element} key={route.path} />
        ))}
      </Route>
      <Route element={<Auth />}>
        {authLayoutRoutes.map((route) => (
          <Route path={route.path} element={route.element} key={route.path} />
        ))}
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
