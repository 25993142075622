import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface State {
  selectedCategory: string;
  selectedColor: string;
  selectedPrice: string;
  selectedOrderBy: string;
  selectedTitle: string;
}

const initialState: State = {
  selectedCategory: '',
  selectedColor: '',
  selectedPrice: '',
  selectedOrderBy: '',
  selectedTitle: 'All Categories',
};

export const productListSlice = createSlice({
  name: 'product',
  initialState: initialState,
  reducers: {
    setSelectedCategory(state, { payload }: PayloadAction<string>) {
      state.selectedCategory = payload;
    },
    setSelectedColor(state, { payload }: PayloadAction<string>) {
      state.selectedColor = payload;
    },
    setSelectedPrice(state, { payload }: PayloadAction<string>) {
      state.selectedPrice = payload;
    },
    setSelectedOrderBy(state, { payload }: PayloadAction<string>) {
      state.selectedOrderBy = payload;
    },
    setSelectedTitle(state, { payload }: PayloadAction<string>) {
      state.selectedTitle = payload;
    },
  },
});

export const {
  setSelectedCategory,
  setSelectedColor,
  setSelectedOrderBy,
  setSelectedPrice,
  setSelectedTitle,
} = productListSlice.actions;
